<template>
	<div class="pa-2">
		<v-row no-gutters>
			<v-col sm="12">
				<v-row no-gutters>
					<!--
						ALL COLUMNS WILL DISPLAY IN THIS SIZE 11 COLUMN
					 -->
					<v-col sm="11">
						<v-row no-gutters>
							<!-- FIRST COLUMN which is a fixed column of aspects' names -->
							<v-col sm="3">
								<!-- Static heading -->
								<v-card height="75" :class="['greyLighten2', 'mt-2']">
									<v-container class="fill-height px-0" fluid>
										<v-card-text :class="fixedColHeaderClasses" align="center">
											{{ $t("app.parameters") }}
										</v-card-text>
									</v-container>
								</v-card>
								<!-- Display Aspects -->
								<v-card
									v-for="(aspect, aspectIndex) in aspects"
									:key="aspectIndex"
									height="65"
									:class="[oddEvenColorClass(aspectIndex + 1), 'mt-2']"
								>
									<v-card-text class="body-2">
										<v-text-field
											v-model="aspect.title"
											hide-details
											solo
											flat
											:background-color="oddEvenColorClass(aspectIndex + 1)"
											dense
										>
											<v-icon
												v-if="aspects.length > 2"
												slot="prepend"
												color="error"
												@click="removeAspect(aspectIndex)"
												>{{ $vuetify.icons.values.close }}
											</v-icon>
										</v-text-field>
									</v-card-text>
								</v-card>
								<!-- Calculation- at the and of each subtopic column -->
								<v-row no-gutters>
									<v-card
										width="100%"
										height="80"
										class="mt-1 deepPurpleLighten2"
									>
										<v-container class="fill-height px-0" fluid>
											<v-row>
												<v-col
													class="white--text font-weight-bold headline"
													align="center"
												>
													{{ $t("app.total") }}
												</v-col>
											</v-row>
										</v-container>
									</v-card>
								</v-row>
								<!-- Add Aspects button -->
								<v-row class="mt-4" @click="addAspect()">
									<v-card flat width="100%">
										<v-card-text :class="addDetailClasses" class="cursor-wr">
											<v-icon color="amberDarken1" large
												>$vuetify.icons.values.plus</v-icon
											>
											{{ $t("app.add_aspects") }}
										</v-card-text>
									</v-card>
								</v-row>
							</v-col>

							<!-- REST COLUMNS to display all subtopics -->
							<v-col
								v-for="(subtopic, subtopicIndex) in subtopics"
								:key="subtopicIndex"
								class="ms-1"
							>
								<!-- Display each subtopic's answers -->
								<v-card
									v-for="(answer, answerIndex) in subtopic.answers"
									:key="answerIndex"
									:height="answerIndex == 0 ? 75 : 65"
									:class="[oddEvenColorClass(answerIndex), 'mt-2 mx-1']"
								>
									<v-card-text align="center">
										<v-text-field
											v-model="answer.value"
											:type="answerIndex === 0 ? 'text' : 'number'"
											solo
											hide-details
											background-color="white"
											:style="{ width: answerIndex === 0 ? '' : '45px' }"
											dense
											:placeholder="
												answerIndex == 0
													? getPlaceholder(subtopicIndex + 1)
													: ''
											"
											@blur="saveSubtopic(subtopicIndex)"
										>
											<v-icon
												v-if="answerIndex == 0 && subtopics.length > 2"
												slot="append-outer"
												color="error"
												@click="removeSubtopic(subtopicIndex)"
												>{{ $vuetify.icons.values.close }}
											</v-icon>
										</v-text-field>
									</v-card-text>
								</v-card>
								<!-- Calculation- at the and of each subtopic column -->
								<v-row no-gutters>
									<v-card
										width="100%"
										height="80"
										class="mt-1 deepPurpleLighten2"
									>
										<v-container class="fill-height px-0" fluid>
											<v-row>
												<v-col
													class="white--text font-weight-bold headline"
													align="center"
												>
													{{ calculate(subtopicIndex) }}
												</v-col>
											</v-row>
										</v-container>
									</v-card>
								</v-row>
							</v-col>
						</v-row>
					</v-col>

					<!--
						THE ADD SUBTOPIC COLUMN WILL DISPLAY IN THIS SIZE 1 COLUMN
					 -->
					<v-col sm="1" align="center" class="mt-6">
						<div
							class="cursor-wr"
							@click="addSubtopic()"
							:class="addDetailClasses"
						>
							<div>
								<v-icon large color="amberDarken1"
									>$vuetify.icons.values.plus</v-icon
								>
							</div>
							<div class="wr_indigo_2--text font-weight-bold">
								{{ $t("app.add_subtopic") }}
							</div>
						</div>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { convertNumberToAlphabet, convertToNum } from "@/utils/helpers";
import { mapState } from "vuex";

export default {
	name: "SetPriority",

	data() {
		return {};
	},

	computed: {
		...mapState({
			aspects: (state) => state.temporary.aspects,
			subtopics: (state) => state.temporary.subtopics,
		}),

		fixedColHeaderClasses() {
			return [
				"wr_indigo_2--text",
				"subtitle-1",
				"font-weight-bold",
				"text-truncate",
			];
		},

		addDetailClasses() {
			return "subtitle-1 wr_indigo_2--text font-weight-bold";
		},
	},

	created() {
		this.readyToListenEvents();
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("sp-submit-subtopic-questionnaire", (payload) => {
				this.$eventBus.$emit("sp-redirect-next");
			});
		},

		oddEvenColorClass(index) {
			return index % 2 == 0 ? "greyLighten2" : "deepPurpleLighten4";
		},

		getPlaceholder(number) {
			return this.$t("app.placeholder.type_subtopic", {
				alphabet: convertNumberToAlphabet(number),
			});
		},

		addSubtopic() {
			this.$store.commit("temporary/ADD_SUBTOPIC");
		},

		addAspect() {
			this.$store.commit("temporary/ADD_ASPECT");
		},

		removeAspect(index) {
			this.$store.commit("temporary/REMOVE_ASPECT", {
				index,
			});
		},

		removeSubtopic(index) {
			this.$store.commit("temporary/REMOVE_SUBTOPIC", {
				index,
			});
		},

		saveSubtopic(index) {
			this.$store.commit("temporary/SAVE_SUBTOPIC", {
				index,
				subtopic: this.subtopics[index],
			});
		},

		calculate(subtopicIndex) {
			let count = 0;

			this.subtopics[subtopicIndex].answers.forEach((answer, index) => {
				/**
				 * Because 0th index is having name, we will not count that.
				 */
				if (index !== 0) {
					count += convertToNum(answer.value);
				}
			});
			return count;
		},
	},
};
</script>

<style scoped>
/** Hide the up and down arrows from input type number */

/* Chrome, Safari, Edge, Opera */
>>> input::-webkit-outer-spin-button,
>>> input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
	margin: 0 !important;
}

/* Firefox */
>>> input[type="number"] {
	-moz-appearance: textfield !important;
}
</style>
