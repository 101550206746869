<template>
	<v-row no-gutters align="center" justify="center" class="fill-height">
		<template v-for="(item, index) in options">
			<v-card
				:key="index"
				width="290"
				height="270"
				align="center"
				justify="center"
				class="cursor-wr d-inline-block mx-3 priority-card-wr"
			>
				<v-container class="fill-height">
					<v-row no-gutters>
						<v-col align="center">
							<div class="greyLighten4 priority-score-parent-wr">
								<div
									:style="{
										height:
											item.score == 0
												? '0px'
												: (150 * item.score) / maxScore + 'px',
										width: '100%',
										background: generateRandomColor(),
									}"
								>
									<div
										:class="['py-2', 'priority-score-text-wr', 'black--text']"
									>
										<div class="headline font-weight-bold">
											{{ item.score }}
										</div>
										<div class="body-2">{{ $t("app.score") }}</div>
									</div>
								</div>
							</div>

							<!-- (4) Title of every card -->
							<v-card-text
								class="pt-6 title card-title-wr font-weight-black black--text text-truncate"
							>
								{{ item.title }}
							</v-card-text>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</template>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import {
	convertToNum,
	generateRandomColor,
	sortArrayOfObjects,
} from "@/utils/helpers";

export default {
	name: "DisplayScore",

	data() {
		return {
			generateRandomColor,
		};
	},

	computed: {
		...mapState({
			subTopics: (state) => state.temporary.subtopics,
		}),

		options() {
			let data = [];
			this.subTopics.forEach((subTopic) => {
				let obj = {};
				let sum = 0;

				// 0th index answer will always be the name of subtopic
				obj.title = subTopic.answers[0].value;
				// Sum all answers given by user
				subTopic.answers.forEach((item, index) => {
					if (index !== 0) {
						sum += convertToNum(item.value);
					}
				});
				obj.score = sum;

				data.push(obj);
			});

			return sortArrayOfObjects(data, "score");
		},

		maxScore() {
			let data = [];
			this.subTopics.forEach((subTopic) => {
				let sum = 0;
				subTopic.answers.forEach((item, index) => {
					if (index !== 0) {
						sum += convertToNum(item.value);
					}
				});
				data.push(sum);
			});
			return Math.max(...data);
		},
	},
};
</script>
