<template>
	<component v-if="current_step" :is="current_step.component_name"></component>
</template>

<script>
import SetPriority from "@/components/shared/tools/setPriority/Index.vue";
import DisplayScore from "@/components/shared/tools/setPriority/DisplayScore.vue";
import { mapFields } from "vuex-map-fields";
import { setPrioritySteps } from "@/utils/steps";

export default {
	name: "SetPriorityPage",

	components: {
		SetPriority,
		DisplayScore,
	},

	computed: {
		...mapFields("temporary", {
			current_step: "set_priority_current_step",
		}),
	},

	mounted() {
		this.setCurrentStep();
	},

	created() {
		this.readyToListenEvents();
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("sp-draft-subtopics-questionnaire", (payload) => {
				this.current_step = setPrioritySteps[0];
			});
			this.$eventBus.$on("sp-redirect-next", (payload) => {
				this.current_step = setPrioritySteps[1];
			});
		},

		setCurrentStep() {
			try {
				if (!this.current_step) {
					this.current_step = setPrioritySteps[0];
				}
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>
