var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","align":"center","justify":"center"}},[_vm._l((_vm.options),function(item,index){return [_c('v-card',{key:index,staticClass:"cursor-wr d-inline-block mx-3 priority-card-wr",attrs:{"width":"290","height":"270","align":"center","justify":"center"}},[_c('v-container',{staticClass:"fill-height"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"align":"center"}},[_c('div',{staticClass:"greyLighten4 priority-score-parent-wr"},[_c('div',{style:({
									height:
										item.score == 0
											? '0px'
											: (150 * item.score) / _vm.maxScore + 'px',
									width: '100%',
									background: _vm.generateRandomColor(),
								})},[_c('div',{class:['py-2', 'priority-score-text-wr', 'black--text']},[_c('div',{staticClass:"headline font-weight-bold"},[_vm._v(" "+_vm._s(item.score)+" ")]),_c('div',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.$t("app.score")))])])])]),_c('v-card-text',{staticClass:"pt-6 title card-title-wr font-weight-black black--text text-truncate"},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)],1)],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }